<template>
  <div class="c-timer">
    <div class="c-timer__inner">
      <span class="c-timer__num">{{ formattedTimerNow }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'c-timer',
  props: [],
  data () {
    return {

    }
  },
  mounted() {
    this.$store.dispatch('initTimer');
  },
  methods: {
  },
  computed: {
    sheetData() {
      return this.$store.getters.sheetData;
    },
    formattedTimerNow() {
      return this.$store.getters.formattedTimerNow;
    }
  }
}
</script>

<style lang="scss">

  @import '../styles/helpers';

  .c-timer {
    position: fixed;
    top: 4.5rem;
    left: 0;
    width: 100%;
    height: 2rem;
    z-index: 2;

    &__inner {
      background-color: #222;
      margin: 0 auto;
      max-width: 850px;
      padding: 0 1.5rem;
      box-sizing: border-box;
    }

    &__num {
      display: block;
      line-height: 2rem;
      font-size: 1.2rem;
      font-weight: 700;
      color: #fff;
      text-align: center;
    }
  }

</style>
