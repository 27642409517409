<template>
  <div class="c-start">
    <div class="c-start__content" v-if="sheetData">
      <div
        class="c-start__hero"
        v-bind:style="'background-image:url(' + sheetData.hero_medium_url + ')'"
        v-if="sheetData.hero_image"
      ></div>
      <h1>{{ sheetData.name }}</h1>
      <div v-html="compiledDescription"></div>
    </div>
    <button @click="startSheet" class="c-start__button">Start</button>
  </div>
</template>

<script>
import marked from "marked";
import Crawler from "es6-crawler-detect";

export default {
  name: "start",
  metaInfo() {
    return {
      title: this.sheetTitle,
      meta: [
        {
          property: "og:image",
          content: this.sheetImage,
          vmid: "og:description",
        },
      ],
    };
  },
  mounted() {
    if (this.sheetData) this.$store.dispatch("initTimer");
    this.$store.commit("clearAnswers");

    this.$store.commit("setHeader", {
      title: "SchulArena.com",
      subtitle: "Onlineübungen",
    });
    this.$store.commit("setSheetProgress", 0);
  },
  watch: {
    sheetData(to, from) {
      if(to) this.checkCrawler();
    }
  },
  methods: {
    startSheet() {
      if (this.sheetData.random_order == 1) {
        this.$store.dispatch("randomizeTasks");
      }

      this.$store.commit("setRouterTransition", "slide-left");
      this.$store.dispatch("startTimer");
      this.$router.push({ path: this.nextRoute });
    },
    checkCrawler() {
      let CrawlerDetector = new Crawler.Crawler();
      let userAgentString = navigator.userAgent;

      if (CrawlerDetector.isCrawler(userAgentString)) {
        if(!this.sheetData.public) {
          this.$router.push('/');
        }
      }
    },
  },
  computed: {
    sheetId() {
      return this.$store.getters.sheetId;
    },
    sheetData() {
      return this.$store.getters.sheetData;
    },
    sheetTitle() {
      if (!this.sheetData) return "...";
      return this.sheetData.name;
    },
    sheetImage() {
      if (!this.sheetData) return "#";
      return this.sheetData.hero_medium_url;
    },
    nextRoute() {
      if (this.sheetData.media.length > 0) {
        return "/sheets/" + this.sheetId + "/media/0";
      } else if (this.sheetData.tasks.length > 0) {
        return "/sheets/" + this.sheetId + "/tasks/0";
      } else {
        return "/";
      }
    },
    compiledDescription() {
      if(!this.sheetData.description) {
        return '';
      }
      return marked(this.sheetData.description);
    },
  },
};
</script>

<style lang="scss">
@import "../styles/helpers";

.c-start {
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 25rem;
    background: linear-gradient(180deg, lighten($c-red, 45%), #fff);
  }

  h1 {
    font-size: 2rem;
  }

  &__hero {
    // position: absolute;
    // top: 0;
    // left: 0;
    width: 100%;
    height: 30vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: 1rem;
  }

  &__button {
    position: fixed;
    font-family: $f-body;
    bottom: 1.5rem;
    left: 50%;
    display: block;
    background: $c-red;
    border: 2px solid $c-red-dark;
    text-shadow: 0 2px 0 $c-red-dark;
    box-shadow: 0 5px 10px 0 rgba($c-red-dark, 0.15);
    font-size: 1rem;
    font-weight: 700;
    color: #fff;
    padding: 0;
    height: 2.5rem;
    line-height: 2.5rem;
    border-radius: 2.5rem;
    text-decoration: none;
    text-align: center;
    width: calc(100% - 3rem);
    max-width: calc(850px - 3rem);
    transform: translateX(-50%);
    box-sizing: content-box;
    z-index: 2;

    &:focus {
      outline: none;
      border-color: darken($c-red, 30%);
    }
    &:hover {
      background-color: $c-red-light;
    }
  }
}

.c-start__content {
  position: relative;
  z-index: 2;

  @include routerTransition;
}
</style>
