<template>
  <div class="c-link">
    <a target="_blank" class="c-link__link" :href="media.url">{{ mediaTitle }}</a>
  </div>
</template>

<script>
export default {
  name: 'c-link',
  props: ['media'],
  data () {
    return {

    }
  },
  computed: {
    mediaTitle() {
      if(this.media.title) {
        return this.media.title;
      }else{
        return 'Link';
      }
    }
  },
  methods: {

  }
}
</script>

<style lang="scss">

  @import '../styles/helpers';

  .c-link {

    &__link {
      color: $c-red;
      text-decoration: underline;
      font-weight: bold;

      &:hover {
        color: $c-red-dark;
      }
    }
  }

</style>
