<template>
  <div class="c-error">
      <div class="c-error__logo">
        <a href="/"><img src="/images/sc-logo@2x.png"></a>
      </div>
    <h1>Error 404</h1>
    <p>Diese Seite wurde nicht gefunden.</p>
    <p><a href="/" class="c-error__homelink">Zur Startseite</a></p>
  </div>
</template>

<script>
export default {
  name: 'error',
  data () {
    return {
    }
  },
  components: {
  },
  mounted() {
  },
  watch: {
  },
  computed: {
    currentYear() {
      return (new Date()).getFullYear();
    }
  }
}
</script>

<style lang="scss" scoped>

  @import '../styles/helpers';

  .c-error {
    margin-top: -3rem;
    margin-bottom: -4rem;
    text-align: center;

    h1 {
    }
    p {
      max-width: none;
    }
    &__logo {
      width: 100%;
      max-width: 400px;
      margin: 0 auto 4rem auto;

      img {
        width: 100%;
        height: auto;
      }
    }
    &__homelink {
      display: inline-block;
      margin-top: 2rem;
      color: $c-red-dark;
      text-decoration: none;
    }
  }

</style>
