<template>
  <div class="sharemodal" @click="$emit('close')">
    <div class="sharemodal__inner" @click.stop v-if="shareSheet">
      <button @click="$emit('close')" class="sharemodal__close">✕</button>
      <div class="sharemodal__content">
        <h1>Teilen</h1>
        <a :href="qrUrl + '?download'" target="_blank"><img width="500" height="500" class="qrcode" :src="qrUrl" alt="QR Code"></a>
        <h2>Quickstart-Code</h2>
        <div class="sharemodal__contentbox">
          <p class="sharetext" @click="selectText">{{ shareSheet.quickstart }}</p>
          <button class="sharemodal__copybtn" :class="{ 'done':(copied=='qs') }" @click="copyText(shareSheet.quickstart, 'qs')"><span>Kopieren</span></button>
        </div>
        <h2>URL</h2>
        <div class="sharemodal__contentbox">
          <p class="sharetext" @click="selectText">{{ shareUrl }}</p>
          <button class="sharemodal__copybtn" :class="{ 'done':(copied=='url') }" @click="copyText(shareUrl, 'url')"><span>Kopieren</span></button>
        </div>
        <h2>Embed-Code</h2>
        <div class="sharemodal__contentbox">
          <p class="sharetext" @click="selectText">{{ embedCode }}</p>
          <button class="sharemodal__copybtn" :class="{ 'done':(copied=='embed') }" @click="copyText(embedCode, 'embed')"><span>Kopieren</span></button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'sharemodal',
  data () {
    return {
      copied: null
    }
  },
  components: {
  },
  watch: {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    copyText(text, slug) {
      document.addEventListener('copy', (event) => {
        event.clipboardData.setData('text/plain', text)
        event.preventDefault();
        this.copied = slug;
      }, {once: true});
      document.execCommand('copy');
    },
    selectText(e) {
      let node = e.target;
      if (document.body.createTextRange) {
          const range = document.body.createTextRange();
          range.moveToElementText(node);
          range.select();
      } else if (window.getSelection) {
          const selection = window.getSelection();
          const range = document.createRange();
          range.selectNodeContents(node);
          selection.removeAllRanges();
          selection.addRange(range);
      } else {
          console.warn("Could not select text in node: Unsupported browser.");
      }
    }
  },
  computed: {
    qrUrl() {
      return process.env.VUE_APP_APIURL + 'api/tasksheets/' + this.shareSheet.quickstart + '/qrcode';
    },
    shareSheet() {
      return this.$store.state.shareSheet;
    },
    shareUrl() {
      return 'https://onlineuebungen.schularena.com/sheets/' + this.shareSheet.quickstart;
    },
    embedCode() {
      return `<iframe width="400" height="600" src="${this.shareUrl}" frameborder="0"></iframe>`;
    }
  }
}
</script>

<style lang="scss" scoped>

  @import '../styles/helpers';

  .sharemodal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(#f0f0f0, .8);
    z-index: 4;
    overflow-y: scroll;
    display: flex;
    align-items: flex-start;
    justify-content: center;

    h1 {
      font-size: 1.5rem;
      margin-bottom: 1rem;
    }
    
    &__inner {
      margin: 2rem 0;
      width: calc(100% - 2rem);
      max-width: 650px;
      background-color: #fff;
      border: 1px solid #eee;
      border-radius: 4px;
      box-shadow: 0 5px 15px -2px rgba(#333, .1);
      padding: 1rem;
      box-sizing: border-box;
      position: relative;

      @include desktop() {
        padding: 2rem;
      }
    }
    &__close {
      position: absolute;
      top: 0;
      right: 0;
      background: none;
      border: none;
      font-family: $f-body;
      font-size: 1rem;
      background-color: #fff;
      width: 2rem;
      height: 2rem;
      line-height: 2rem;
      padding: 0;
      box-sizing: content-box;
      z-index: 2;
      text-align: center;
      cursor: pointer;
    }
    &__contentbox {
      position: relative;
    }
    &__copybtn {
      --webkit-appearance: none;
      background: none;
      background-image: url('../assets/icon-copy.svg');
      background-size: 60%;
      background-repeat: no-repeat;
      background-position: center;
      border: none;
      width: 2rem;
      height: 2rem;
      position: absolute;
      top: .25rem;
      right: .25rem;
      background-color: #fff;
      border-radius: 4px;
      border: 1px solid #ccc;
      cursor: pointer;

      &:hover {
        outline: none;
        border-color: #333;
      }
      &:focus, &:active {
        outline: none;
        background-color: #fafafa;
      }

      span {
        display: none;
      }
      &.done {
        background-image: url('../assets/icon-copy-done.svg');
      }
    }
  }
  h2 {
    margin: 1rem 0 .25rem 0;
  }
  .sharetext {
    background-color: #f0f0f0;
    padding: 0 .5rem;
    line-height: 2.5rem;
    border-radius: 4px;
    overflow: hidden;
    white-space: nowrap;
  }
  .qrcode {
    display: block;
    width: 100%;
    max-width: 300px;
    margin: 0 auto 2rem auto;
    height: auto;
  }

  .modal-enter-active, .modal-leave-active {
    transition: .5s opacity;

    .sharemodal__inner {
      transition: .5s transform $easeOutQuint;
    }
  }
  .modal-enter, .modal-leave-to {
    opacity: 0;

    .sharemodal__inner {
      transform: translateY(50px);
      transition: .5s transform ease-in;
    }
  }

</style>
