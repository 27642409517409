<template>
  <transition name="modal">
    <div class="scoredetail" @click="$emit('close')">
      <div class="scoredetail__inner" v-if="sheetData" @click.stop>
        <button @click="$emit('close')" class="scoredetail__close">✕</button>
        <div class="scoredetail__content">
          <h1>Detailauswertung</h1>
          <component
            v-for="(task, index) in sheetData.tasks"
            v-bind:is="detailView(task)"
            :task="task"
            :taskid="index"
            :key="task.id"
          >
          </component>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import GapsTaskScore from './score/GapsTaskScore';
import WordsTaskScore from './score/WordsTaskScore';
import MulchoTaskScore from './score/MulchoTaskScore';
import InputTaskScore from './score/InputTaskScore';

export default {
  name: 'scoredetail',
  data () {
    return {
    }
  },
  components: {
    'c-gapstaskscore': GapsTaskScore,
    'c-inputtaskscore': InputTaskScore,
    'c-mulchotaskscore': MulchoTaskScore,
    'c-wordstaskscore': WordsTaskScore
  },
  watch: {

  },
  mounted() {

  },
  methods: {
    detailView(task) {
      if(task.taskable_type == 'App\\Tasks\\MulchoTask') {
        return 'c-mulchotaskscore';
      }else if(task.taskable_type == 'App\\Tasks\\InputTask') {
        return 'c-inputtaskscore';
      }else if(task.taskable_type == 'App\\Tasks\\GapsTask') {
        return 'c-gapstaskscore';
      }else if(task.taskable_type == 'App\\Tasks\\WordsTask') {
        return 'c-wordstaskscore';
      }
      return null;
    }
  },
  computed: {
    sheetId() {
      return this.$store.getters.sheetId;
    },
    sheetData() {
      return this.$store.getters.sheetData;
    },
    answers() {
      return this.$store.getters.answers;
    },
    answerCount() {
      return Object.keys(this.answers).length;
    }
  }
}
</script>

<style lang="scss">
@import "../styles/helpers";

.c-score .scoredetail {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(#f0f0f0, 0.8);
  z-index: 4;
  overflow-y: scroll;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  h1 {
    font-size: 1.25rem;
    margin-bottom: 1rem;

    @include desktop {
      font-size: 1.5rem;
    }
  }

  &__inner {
    margin: 1rem 0;
    width: calc(100% - 2rem);
    max-width: 850px;
    background-color: #fff;
    border: 1px solid #eee;
    border-radius: 4px;
    box-shadow: 0 5px 15px -2px rgba(#333, 0.1);
    padding: 1.5rem 1rem;
    box-sizing: border-box;
    position: relative;

    @include desktop {
      padding: 1rem;
    }
  }
  &__close {
    position: absolute;
    top: 0;
    right: 0;
    background: none;
    border: none;
    font-family: $f-body;
    font-size: 1rem;
    background-color: #fff;
    width: 2rem;
    height: 2rem;
    line-height: 2rem;
    padding: 0;
    box-sizing: content-box;
    z-index: 2;
    text-align: center;
    cursor: pointer;
  }
}

.modal-enter-active,
.modal-leave-active {
  transition: 0.5s opacity;

  .scoredetail__inner {
    transition: 0.5s transform $easeOutQuint;
  }
}
.modal-enter,
.modal-leave-to {
  opacity: 0;

  .scoredetail__inner {
    transform: translateY(50px);
    transition: 0.5s transform ease-in;
  }
}
</style>
