<template>
  <div class="c-inputtask">
    <div class="c-inputtask__question" v-html="compiledQuestion"></div>
    <div v-if="description" class="c-task__description" v-html="description"></div>
    <div class="c-inputtask__input">
      <input ref="answer" @keyup.enter="$emit('proceed')" placeholder="Antwort eingeben" type="text" v-model="input" v-bind:class="{ correct: correct }">
    </div>
  </div>
</template>

<script>
import marked from 'marked';

export default {
  name: 'inputtask',
  props: ['taskEntering', 'task', 'description', 'parent'],
  data () {
    return {
      input: null,
      correct: 0
    }
  },
  mounted() {
    if(this.$store.getters.answers[this.taskId]) {
      this.input = this.$store.getters.answers[this.taskId].content;
    }
  },
  watch: {
    input: function(val) {
      this.checkInput(val);
    },
    taskEntering: function(val) {
      if(val == false) this.$refs.answer.focus();
    }
  },
  methods: {
    checkInput(val) {
      let self = this;

      let isCorrect = false;
      this.task.answers.forEach(el => {
        let a = el.answer.trim();
        let b = val.trim();

        if(this.task.check_capitalization == 0) {
          a = a.toLowerCase();
          b = b.toLowerCase();
        }
        if(this.task.check_punctuation == 0) {
          a = a.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g,"");
          b = b.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g,"");
        }
        a = a.replace(/[`’‘‛]/g,"'");
        b = b.replace(/[`’‘‛]/g,"'");
        if(a == b) isCorrect = true;
      });
      if(isCorrect) {
        this.correct = 1;
      } else {
        this.correct = 0;
      }

      if(this.task.selfcheck) {
        this.correct = 2;
      }

      this.$store.commit('addAnswer', {
        id: this.taskId,
        content: this.input,
        correct: this.correct
      });
    }
  },
  computed: {
    sheetData() {
      return this.$store.getters.sheetData;
    },
    sheetId() {
      return this.$route.params.sheetId;
    },
    taskId() {
      return this.parent.id;
      // return this.$route.params.taskId;
    },
    compiledQuestion() {
      return marked(this.task.question);
    }
  }
}
</script>

<style lang="scss">

  @import '../../styles/helpers';

  .c-inputtask {

    &__question {
      font-weight: bold;
      margin-bottom: 1rem;
      font-size: 1.25rem;
      width: 100%;

      @include desktop() {
        font-size: 1.5rem;
      }
    }
    &__input {
      margin-top: 2rem;

      input {
        width: 100%;
        font-family: $f-body;
        font-size: 1rem;
        padding: .5rem .5rem;
        box-sizing: border-box;
        background: #F6F6F6;
        border: 1px solid #E0E0E0;
        -webkit-appearance: none;
        border-radius: 0;

        &:focus {
          outline: none;
          border-color: #333;
        }
      }
    }

  }

</style>
