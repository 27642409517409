<template>
  <div class="c-sheetpreviewlist" v-if="sheets">
    <div class="c-sheetpreviewlist__item" v-for="(sheet, index) in truncatedSheets" :key="sheet.id">
      <ListAd v-if="index == 3 && ad" :ad="ad" />
      <SheetPreview v-else :sheet="sheet" />
    </div>
    <a class="showmore" href="#" @click.prevent="showMore" v-if="limit <= sheets.length">Mehr Übungen</a>
  </div>
</template>

<script>
import SheetPreview from './SheetPreview';
import ListAd from './ListAd';

export default {
  name: 'c-sheetpreviewlist',
  props: ['sheets', 'ad'],
  data () {
    return {
      limit: 12
    }
  },
  components: {
    SheetPreview,
    ListAd
  },
  mounted() {
  },
  methods: {
    showMore() {
      this.limit += 12;
    }
  },
  computed: {
    truncatedSheets() {
      return this.sheets.slice(0, this.limit);
    }
  }
}
</script>

<style lang="scss" scoped>

  @import '../styles/helpers';

  .c-sheetpreviewlist {
    display: flex;
    flex-wrap: wrap;
    margin-top: 2rem;
    width: calc(100% + 1rem);
    margin-left: -.5rem;
    
    &__item {
      width: 100%;
      margin-bottom: 1.5rem;
      padding: 0 .5rem;
      box-sizing: border-box;
    }

    @media screen and (min-width: 440px) {
      &__item {
        width: 50%;
      }
    }
    @include desktop() {
      
      &__item {
        width: 33.3%;
        margin-bottom: 2rem;
      }
    }
    @media screen and (min-width: 1000px) {
      &__item {
        width: 25%;
      }
    }
  }
  .showmore {
    // text-decoration: none;
    font-size: .8rem;
    text-align: center;
    display: block;
    width: 100%;
  }

</style>
