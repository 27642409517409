<template>
  <div class="c-wordstask">
    <div class="c-wordstask__title" v-html="compiledTitle"></div>
    <div
      v-if="description"
      class="c-task__description"
      v-html="description"
    ></div>
    <div
      class="c-wordstask__text"
      ref="text"
      v-html="parsedText"
      @click.prevent="handleTextClick"
    ></div>
  </div>
</template>

<script>
import Vue from "vue";

import marked from "marked";

export default {
  name: "wordstask",
  props: ["task", "description"],
  data() {
    return {};
  },
  mounted() {},
  beforeDestroy() {
    this.checkAnswers();
  },
  watch: {},
  methods: {
    handleTextClick(e) {
      let el = e.target;
      if (el.nodeName != "A") return;

      el.classList.toggle("active");

      // this.checkAnswers();
    },
    checkAnswers() {
      let el = this.$refs.text;
      let words = el.children;
      words = Array.from(words).filter((w) => {
        return w.tagName != "BR";
      });
      for (let i = 0; i < words.length; i++) {
        let w = words[i];
        if (!w.classList.contains("active")) continue;
        let cor = false;
        if (w.classList.contains("cor")) {
          cor = true;
        }

        let obj = {
          id: this.task.id + "w" + i,
          content: w.innerText,
          correct: cor,
        };
        this.$store.commit("addAnswer", obj);
      }
    },
  },
  computed: {
    sheetData() {
      return this.$store.getters.sheetData;
    },
    sheetId() {
      return this.$route.params.sheetId;
    },
    taskId() {
      return this.$route.params.taskId;
    },
    currentTask() {
      if (!this.sheetData) return;
      return this.sheetData.tasks[this.taskId];
    },
    compiledTitle() {
      return marked(this.task.title);
    },
    parsedText() {
      if (!this.task) return;
      let txt = this.task.text.replaceAll(
        /(?:\$\{([a-zA-Z0-9\x7f-\xff'-]+)\})*([a-zA-Z0-9\x7f-\xff'-]+)*/g,
        (match, str1, str2) => {
          if (str1 !== undefined) {
            return `<a class="cor" href="#">${str1}</a>`;
          } else if (str2 !== undefined) {
            return `<a href="#">${str2}</a>`;
          } else {
            return "";
          }
        }
      );
      txt = txt.replaceAll("\n", "<br>");
      txt += "&nbsp;";
      return txt;
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/helpers";

.newgap {
  background: #aff;
}

.c-wordstask {
  &__title {
    font-weight: bold;
    margin-bottom: 1rem;
    font-size: 1.25rem;
    width: 100%;

    @include desktop() {
      font-size: 1.5rem;
    }
  }

  &__text {
    margin-top: 2rem;
    line-height: 1.6;
    p {
      max-width: none;
    }
    a {
      text-decoration: none;
      background-color: #f5f5f5;
      background-color: #fff;
      padding: 2px 4px;
      border-radius: 4px;
      line-height: 1.9;
      color: #444;
      border: 1px solid #f0f0f0;
      transition: 0.2s background-color, 0.2s color, 0.2s border-color;

      &:hover {
        // background-color: #ddd;
      }
      &.cor {
        // color: red;
      }
      &.active {
        background-color: #819a9e;
        border-color: rgb(68, 84, 94);
        color: #fff;
      }
    }
  }
}
</style>
