<template>
  <div class="c-searchbar"
       :class="{ loading: loading }">
    <div class="c-searchbar__input">
      <input @keyup.enter="makeSearch"
             type="text"
             v-model="query"
             placeholder="Suchbegriff oder Quickstart-Code">
    </div>
    <div class="c-searchbar__categories"
         v-if="categories && showCategories">
      <ul>
        <li v-for="cat in categories"
            :key="cat.id"
            :class="{ 'active': cat.active }">
          <a href="#"
             @click.prevent="cat.active = !cat.active">{{ cat.title }}</a>
        </li>
      </ul>
    </div>
    <div class="c-searchbar__buttons">
      <button class="c-searchbar__btn"
              :class="{ 'inactive': !showButtons }"
              @click="makeSearch">Suche</button>
      <button class="c-searchbar__btn c-searchbar__btn--qs"
              :class="{ 'inactive': (!showButtons || !showQS) }"
              @click="makeQuickstart">Quickstart</button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'c-searchbar',
  props: [],
  data() {
    return {
      query: '',
      loading: false
    }
  },
  watch: {
    query() {
      this.$emit('change', this.query);
    }
  },
  mounted() {
    if (this.$route.query.q) {
      this.query = this.$route.query.q;
    }
  },
  methods: {
    async makeSearch() {
      if (!this.query || this.loading) return;

      const apiUrl = process.env.VUE_APP_APIURL;
      this.loading = true;
      try {
        const sheetData = await axios.get(apiUrl + 'api/tasksheets/check/' + this.query)
      } catch (e) {
        if (this.query == this.$route.query.q && this.activeCategoriesString == this.$route.query.c) return;
        this.$router.push({ path: 'search', query: { q: this.query, c: this.activeCategoriesString } });
        this.loading = false;
        return;
      }
      this.loading = false;
      this.makeQuickstart();
    },
    makeQuickstart() {
      if (!this.query) return;
      this.$store.commit('setRouterTransition', 'slide-left');
      this.$router.push({ name: 'start', params: { sheetId: this.query } });
    }
  },
  computed: {
    showButtons() {
      if (!this.query) return false;
      return true;
    },
    showQS() {
      if (this.query.includes(' ')) return false;
      return true;
    },
    categories() {
      return this.$store.state.categories;
    },
    activeCategoriesString() {
      return this.$store.getters.activeCategoriesString;
    },
    showCategories() {
      return false;
      return this.$route.name === 'search';
    }
  }
}
</script>

<style lang="scss"
       scoped>
      @import '../styles/helpers';

      .c-searchbar {
        margin: 3rem 0 6rem 0;

        &.loading {
          opacity: .5;
          pointer-events: none;
        }

        &__input {
          max-width: 700px;
          position: relative;
          margin: 0 auto;

          input {
            width: 100%;
            font-size: 1rem;
            font-family: $f-body;
            line-height: 3rem;
            padding: 0 1rem;
            padding-left: 3rem;
            border-radius: 1.5rem;
            border: 1px solid #eee;
            // border: none;
            box-sizing: border-box;
            text-align: left;
            box-shadow: 0 1px 12px -4px rgba(#333, .2);
            -webkit-appearance: none;
            transition: .2s box-shadow, .2s border-color;

            &::placeholder {
              color: #ccc;
            }

            &:focus {
              outline: none;
              border-color: #fff;
              box-shadow: 0 2px 16px -4px rgba(#000, .45);
            }
          }

          &::before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 3rem;
            pointer-events: none;
            background-image: url('../assets/icon-search.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: 40%;
            opacity: .4;
          }
        }

        &__buttons {
          height: 2rem;
          margin-top: 2rem;
          text-align: center;
        }

        .c-searchbar__btn {
          --webkit-appearance: none;
          background-color: #333;
          color: #fff;
          font-family: $f-body;
          font-size: 1rem;
          box-shadow: 0 2px 10px -2px rgba(#000, .3);
          font-weight: bold;
          border: none;
          border-radius: 4px;
          padding: 0 2.5rem;
          line-height: 2.5rem;
          margin: 4px .5rem;
          transition: .2s opacity;
          cursor: pointer;

          &:focus {
            outline: none;
            border-color: #aaa;
          }

          &--qs {
            background-color: $c-red;
            color: #fff;
            border-color: $c-red-dark;
          }

          &.inactive {
            opacity: .4;
            pointer-events: none;
          }
        }

        &__categories {
          margin-top: 2rem;

          ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin: 0 auto;
            max-width: 800px;

            li {
              display: inline-block;
              font-size: .8rem;
              margin: .25rem;

              a {
                display: block;
                padding: 0 1rem;
                line-height: 1.75rem;
                text-decoration: none;
                background-color: #fafafa;
                border: 1px solid #eee;
                border-radius: 4px;
                color: #777;
                // box-shadow: 0 1px 8px -2px rgba(#000, .3);
              }

              &.active {

                a {
                  border-color: #333;
                  ;
                  box-shadow: 0 2px 10px -2px rgba(#000, .2);
                  background-color: #666;
                  color: #fff;
                }
              }
            }
          }
        }
      }
    </style>
