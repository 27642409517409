<template>
  <div class="c-pdf">
    <span class="c-pdf__loader" v-if="!loadFailed" :style="loaderStyle"></span>
    <div v-if="!directEmbed" class="c-pdf__iframe">
      <iframe
        :src="staticPdfUrl"
        v-if="!loadFailed"
        frameborder="0"
        @load="handleIframeLoad"
      ></iframe>
      <h3 class="c-pdf__failed" v-if="loadFailed">Das Dokument konnte leider nicht angezeigt werden.<br><a :href="media.url">Direktlink zum PDF</a></h3>
      <a target="_blank" v-if="!loadFailed" class="c-pdf__pdflink" :href="media.url"
        >Direktlink zum PDF</a
      >
    </div>
    <div v-if="directEmbed" class="c-pdf__embed">
      <object
        :data="media.url"
        type="application/pdf"
        width="100%"
        height="800px"
      >
        <p>
          Leider kann das PDF in deinem Browser nicht angezeigt werden.
          <br /><a :href="media.url">Direktlink öffnen</a>
        </p>
      </object>
      <a target="_blank" class="c-pdf__pdflink" :href="media.url"
        >Direktlink zum PDF</a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "c-pdf",
  props: ["media"],
  data() {
    return {
      staticPdfUrl: "",
      directEmbed: true,
      viewerLoaded: false,
      loadRetries: 0,
      loadFailed: false,
    };
  },
  mounted() {
    this.checkBrowser();
    if (!this.directEmbed) {
      this.checkViewer();
      window.setTimeout(() => {
        this.staticPdfUrl = this.pdfUrl;
      }, 1000);
    }
  },
  computed: {
    isImage() {
      return (
        this.media.mime == "image/jpeg" ||
        this.media.mime == "image/gif" ||
        this.media.mime == "image/png"
      );
    },
    isPdf() {
      return this.media.mime == "application/pdf";
    },
    isAudio() {
      return (
        this.media.mime == "audio/mpeg" ||
        this.media.mime == "application/octet-stream"
      );
    },
    isVideo() {
      return this.media.mime == "video/mp4";
    },
    pdfUrl() {
      return (
        "https://docs.google.com/viewer?url=" +
        this.media.url +
        "&embedded=true"
      );
    },
    loaderStyle() {
      return {
        opacity: (this.loadRetries / 6) * 0.95 + 0.05,
      };
    },
  },
  methods: {
    checkViewer() {
      this.staticPdfUrl = "";
      window.setTimeout(() => {
        this.staticPdfUrl = this.pdfUrl;
      }, 1000);
      window.setTimeout(() => {
        if (this.viewerLoaded) return;
        if (this.loadRetries == 5) {
          this.loadFailed = true;
          return;
        }
        this.loadRetries++;
        this.checkViewer();
      }, 5000);
    },
    handleIframeLoad() {
      if (!this.staticPdfUrl) return;
      this.viewerLoaded = true;
    },
    checkBrowser() {
      let isIE = /*@cc_on!@*/ false || !!document.documentMode;
      let isIOS =
        /iPad|iPhone|iPod/.test(navigator.platform) ||
        (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);
      if (isIE || isIOS) {
        this.directEmbed = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import "../styles/helpers";

.c-pdf {
  &__iframe {
    width: 100%;
    height: calc(100vh - 14rem);
    min-height: 300px;
    position: relative;
    z-index: 1;

    iframe {
      width: 100%;
      height: calc(100% - 1.5rem);
    }

    @include desktop {
      height: calc(100vh - 16rem);
    }
  }
  &__embed {
    width: 100%;
    height: calc(100vh - 14rem);
    min-height: 300px;
    position: relative;
    z-index: 1;

    object {
      width: 100%;
      height: calc(100% - 1.5rem);
    }

    @include desktop {
      height: calc(100vh - 16rem);
    }
  }
  &__pdflink {
    display: inline-block;
    font-size: 0.8rem;
    margin-bottom: 0.5rem;
    color: #aaa;
  }
  &__failed {
    text-align: center;
    padding: 2rem;
  }
  &__loader {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 2rem;
    height: 2rem;
    margin-left: -1rem;
    margin-top: -1rem;
    border: 2px solid #000;
    border-bottom-color: transparent;
    border-radius: 100%;
    z-index: 0;
    transition: 0.2s opacity;
    animation: loadingspin linear 1s infinite;
  }
}
</style>
