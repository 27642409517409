<template>
  <transition name="modal">
    <div class="scoreshare" @click="$emit('close')">
      <div class="scoreshare__inner" v-if="sheetData" @click.stop>
        <button @click="$emit('close')" class="scoreshare__close">✕</button>
        <div class="scoreshare__content">
          <h1>Auswertung teilen</h1>
          <div class="scoreshare__form">
            <label>
              <span>Dein Name *</span>
              <input
                :class="{ error: markMissing && !studentName }"
                class="textinput"
                type="text"
                v-model="studentName"
              />
            </label>
            <label>
              <span>Deine E-Mail-Adresse *</span>
              <input
                :class="{ error: markMissing && !studentMail }"
                class="textinput"
                type="email"
                v-model="studentMail"
              />
            </label>
            <label>
              <span>E-Mail des Empfängers *</span>
              <input
                :class="{ error: markMissing && !teacherMail }"
                class="textinput"
                type="email"
                v-model="teacherMail"
              />
            </label>
            <label>
              <span>Nachricht</span>
              <textarea
                class="textinput"
                v-model="studentMessage"
                cols="30"
                rows="2"
              ></textarea>
            </label>
            <div class="scoreshare__disclaimer">
              <p>
                Vor dem Versenden dieser Nachricht und der damit verbundenen
                Auswertung musst du unseren Nutzungsbedingungen / AGB und der
                Datenschutzerklärung zustimmen. Versende keine Nachrichten, die
                andere Personen in irgendeiner Form verletzen. Deine IP-Adresse
                und deine Nachricht werden bei uns gespeichert. Du bist also
                auch bei einer falschen Angabe des Namens
                <strong>nicht</strong> anonym.
              </p>
              <div class="scoreshare__disclaimer__checkbox">
                <input
                  type="checkbox"
                  name=""
                  id="usage"
                  v-model="usageCheck"
                  :class="{ error: markMissing && !usageCheck }"
                />
                <label for="usage"
                  >Ich stimme den
                  <a href="https://www.schularena.com/start/agb" target="_blank"
                    >Nutzungsbedingungen für Onlineübungen</a
                  >
                  zu.</label
                >
              </div>
              <div class="scoreshare__disclaimer__checkbox">
                <input
                  type="checkbox"
                  name=""
                  id="privacy"
                  v-model="privacyCheck"
                  :class="{ error: markMissing && !privacyCheck }"
                />
                <label for="privacy"
                  >Ich stimme der
                  <a
                    href="https://www.schularena.com/start/datenschutzerklaerung"
                    target="_blank"
                    >Datenschutzerklärung</a
                  >
                  zu.</label
                >
              </div>
            </div>
            <button class="scoreshare__send" @click="sendMail" v-if="!mailSent">
              E-Mail senden
            </button>
            <span v-if="mailSent">Sende E-Mail...</span>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import axios from "axios";

export default {
  name: "scoreshare",
  props: ["scoreData"],
  data() {
    return {
      studentName: null,
      studentMessage: null,
      studentMail: null,
      teacherMail: null,
      mailSent: false,
      markMissing: false,
      usageCheck: false,
      privacyCheck: false,
    };
  },
  components: {},
  watch: {},
  mounted() {},
  methods: {
    sendMail() {
      if (
        !this.studentName ||
        !this.teacherMail ||
        !this.studentMail ||
        !this.usageCheck ||
        !this.privacyCheck
      ) {
        this.markMissing = true;
        return;
      }

      this.mailSent = true;
      let apiUrl = process.env.VUE_APP_APIURL;

      let data = {
        email: this.teacherMail,
        studentName: this.studentName,
        studentMail: this.studentMail,
        studentMessage: this.studentMessage,
        sheetId: this.sheetData.id,
        answers: this.answers,
        scoreData: this.scoreData,
      };
      axios
        .post(apiUrl + "api/scores/share", data)
        .then((e) => {
          this.$emit("close");
        })
        .catch((e) => {
          this.mailSent = false;
          console.log(e);
        });
    },
  },
  computed: {
    sheetId() {
      return this.$store.getters.sheetId;
    },
    sheetData() {
      return this.$store.getters.sheetData;
    },
    answers() {
      return this.$store.getters.answers;
    },
    answerCount() {
      return Object.keys(this.answers).length;
    },
  },
};
</script>

<style lang="scss">
@import "../styles/helpers";

.c-score .scoreshare {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(#f0f0f0, 0.8);
  z-index: 4;
  overflow-y: scroll;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  h1 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  &__disclaimer {
    font-size: 0.8rem;

    &__checkbox {
      margin-top: 1rem;
      display: flex;

      & + .scoreshare__disclaimer__checkbox {
        margin-top: 0.5rem;
      }
      label {
        margin-bottom: 0 !important;
        margin-left: 0.25rem;
      }
      input.error {
        filter: drop-shadow(0 0 2px $c-red);

        + label {
          color: $c-red;
        }
      }
    }
  }
  &__inner {
    margin: 1rem 0;
    width: calc(100% - 2rem);
    max-width: 850px;
    background-color: #fff;
    border: 1px solid #eee;
    border-radius: 4px;
    box-shadow: 0 5px 15px -2px rgba(#333, 0.1);
    padding: 1rem;
    box-sizing: border-box;
    position: relative;
  }
  &__close {
    position: absolute;
    top: 0;
    right: 0;
    background: none;
    border: none;
    font-family: $f-body;
    font-size: 1rem;
    background-color: #fff;
    width: 2rem;
    height: 2rem;
    line-height: 2rem;
    padding: 0;
    box-sizing: content-box;
    z-index: 2;
    text-align: center;
    cursor: pointer;
  }
  &__form {
    label {
      display: block;
      margin-bottom: 1rem;

      span {
        display: block;
        font-size: 0.75rem;
        margin-bottom: 0.25rem;
      }
      .textinput {
        display: block;
        font-family: $f-body;
        font-size: 1rem;
        padding: 0.5rem;
        width: 100%;
        border: 1px solid #aaa;
        border-radius: 2px;
        -webkit-appearance: none;
        box-sizing: border-box;

        &:focus,
        &:active {
          outline: none;
          border-color: #000;
          background-color: #fafafa;
        }
        &.error {
          border-color: $c-red;
          background-color: #fee;
        }
      }
    }
  }
  &__send {
    display: block;
    margin-top: 2rem;
    font-size: 1rem;
    background-color: $c-red;
    color: #fff;
    font-family: $f-body;
    font-weight: 700;
    border: 2px solid $c-red-dark;
    padding: 0 2.5rem;
    line-height: 2rem;
    border-radius: 1.5rem;
    cursor: pointer;
    box-sizing: border-box;
    box-shadow: 0 5px 10px 0 rgba($c-red-dark, 0.15);
    margin-bottom: 2rem;

    &:active,
    &:focus {
      outline: none;
    }
  }
}

.modal-enter-active,
.modal-leave-active {
  transition: 0.5s opacity;

  .scoredetail__inner {
    transition: 0.5s transform $easeOutQuint;
  }
}
.modal-enter,
.modal-leave-to {
  opacity: 0;

  .scoredetail__inner {
    transform: translateY(50px);
    transition: 0.5s transform ease-in;
  }
}
</style>
