<template>
  <div id="app"
       v-bind:class="{ timed: timed }">
    <transition name="modal">
      <c-sharemodal v-if="showShareModal"
                    @close="$store.commit('hideShareModal')"></c-sharemodal>
    </transition>
    <div :class="{ content: true, index: isWide }">
      <div class="loader"
           v-if="pending"></div>
      <transition name="msg">
        <div class="msg"
             v-if="showMessage">{{ errorMsg }}</div>
      </transition>
      <c-header v-if="showHeader"></c-header>
      <c-timer v-if="timed"></c-timer>
      <transition name="mainmedia">
        <c-mainmedia v-if="mainMedia"
                     :setMediaHeight="setMediaHeight"></c-mainmedia>
      </transition>
      <div class="component-wrap"
           v-bind:style="{ paddingTop: calculatedPadding }">
        <transition :name="transitionName"
                    mode="out-in"
                    duration="300"
                    v-on:after-enter="afterEnter"
                    v-on:before-enter="beforeEnter">
          <router-view :key="$route.path"
                       :taskEntering="taskEntering"></router-view>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "./components/Header";
import Timer from "./components/Timer";
import MainMedia from "./components/MainMedia";
import ShareModal from "./components/ShareModal";

export default {
  name: "app",
  metaInfo: {
    title: "SchulArena.com Onlineübungen",
    titleTemplate: "%s | SchulArena.com Onlineübungen",
  },
  components: {
    "c-header": Header,
    "c-timer": Timer,
    "c-mainmedia": MainMedia,
    "c-sharemodal": ShareModal,
  },
  data() {
    return {
      showMessage: false,
      mediaHeight: 0,
      taskEntering: false,
    };
  },
  watch: {
    $route: function (to, from) {
      if (!to.params.sheetId) return;
      if (to.params.sheetId == from.params.sheetId) return;

      this.dispatchSheetId(to.params.sheetId);
    },
    errorMsg: function (to, from) {
      if (to != "") {
        this.showMessage = true;
      } else {
        this.showMessage = false;
      }
    },
    timeOut: function (to, from) {
      if (this.timeOut == true) {
        this.$store.commit("error", "Zeit ist abgelaufen");
        this.$store.commit("setRouterTransition", "slide-right");
        this.$router.push({ name: "start", params: { sheetId: this.sheetId } });
      }
    },
  },
  mounted() {
    this.checkSheet();
    this.checkPosition();
    this.$store.dispatch("getCategories");
  },
  computed: {
    showHeader() {
      if (this.$route.name == "start") return true;
      if (this.$route.name == "media") return true;
      if (this.$route.name == "task") return true;
      if (this.$route.name == "score") return true;

      return false;
    },
    transitionName() {
      return this.$store.getters.routerTransition;
    },
    pending() {
      return this.$store.getters.pending;
    },
    errorMsg() {
      return this.$store.getters.errorMsg;
    },
    sheetId() {
      return this.$store.getters.sheetId;
    },
    timeOut() {
      return this.$store.getters.timeOut;
    },
    sheetData() {
      if (!this.$store.getters.sheetData) return null;
      return this.$store.getters.sheetData;
    },
    timed() {
      if (!this.$store.getters.sheetData) return false;

      if (this.sheetData.time_limit > 0) {
        return true;
      }
    },
    mainMedia() {
      if (this.$route.name == "start") return false;
      if (this.$route.name == "score") return false;
      if (this.$route.name == "index") return false;
      if (this.$route.name == "media") return false;

      return this.$store.getters.mainMedia;
    },
    calculatedPadding() {
      let p = 6;
      if (this.timed) p += 2;
      if (this.mainMedia) p += this.mediaHeight;
      return p + "rem";
    },
    isWide() {
      return this.$route.name == "index" || this.$route.name == "search";
    },
    showShareModal() {
      return this.$store.state.showShareModal;
    },
  },
  methods: {
    checkSheet() {
      if (!this.$route.params.sheetId) return;

      this.dispatchSheetId(this.$route.params.sheetId);
    },
    beforeEnter() {
      this.taskEntering = true;
    },
    afterEnter() {
      this.taskEntering = false;
    },
    checkPosition() {
      if (this.$route.params.sheetId) {
        let answers = localStorage.getItem("answers");
        if (answers) {
          answers = JSON.parse(answers);
          this.$store.commit("setAnswers", answers);
        }
      }
      // if(this.$route.params.mediaId || this.$route.params.taskId) {
      // this.$router.push({ name: 'start', params: { sheetId: this.sheetId } });
      // }else{
      // }
    },
    setMediaHeight(h) {
      this.mediaHeight = h;
    },
    dispatchSheetId(id) {
      this.$store
        .dispatch("getSheetData", {
          sheetId: id,
        })
        .catch((val) => {
          console.log(val);
          if (val == 'old') {
            this.$router.push("/unavailable");
          } else {
            this.$router.push("/");
          }
        });
    },
  },
};
</script>

<style src="./styles/global.scss"
       lang="scss"></style>

<style lang="scss">
@import "./styles/helpers";

#app {
  position: relative;
}

.content {
  max-width: 850px;
  height: 100vh;
  box-shadow: 0 0 40px 0 rgba(#000, 0.05);
  margin: 0 auto;
  padding: 0;
  box-sizing: border-box;
  background-color: #fff;
  position: relative;

  &.index {
    max-width: 1300px;
  }
}

.loader {
  position: absolute;
  top: .65rem;
  left: 1.35rem;
  width: 3rem;
  height: 3rem;
  border: 2px solid rgba($c-red, 0.4);
  border-top-color: rgba($c-red, 0.3);
  border-left-color: rgba($c-red, 0.2);
  border-bottom-color: rgba($c-red, 0.05);
  border-radius: 100%;
  z-index: 99;
  animation: loadingspin linear 1.5s infinite;
}

.msg {
  position: absolute;
  top: 1rem;
  left: 1rem;
  width: calc(100% - 2rem);
  padding: 0.5rem 2rem;
  box-sizing: border-box;
  font-weight: 700;
  background-color: rgba(#333, 0.95);
  color: #eee;
  z-index: 3;
  text-align: center;
  min-height: 2.75rem;
  border-radius: 0;
  box-shadow: 0 5px 10px -4px rgba(#000, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
}

.component-wrap {
  padding: 6rem 1.5rem 7rem 1.5rem;
  background: #fff;
  overflow: hidden;
}

@keyframes loadingspin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(365deg);
  }
}

.msg-enter,
.msg-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}

.msg-leave-active,
.msg-enter-active {
  transition: transform 0.3s $easeOutQuint, opacity 0.3s $easeOutQuint;
}

.mainmedia-enter,
.mainmedia-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}

.mainmedia-leave-active,
.mainmedia-enter-active {
  transition: transform 0.6s $easeOutQuint, opacity 0.6s $easeOutQuint;
}

.mainmedia-leave-to {
  transition: transform 0.3s $easeInQuint, opacity 0.3s $easeInQuint;
}
</style>
