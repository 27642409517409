<template>
  <div :class="['c-mainmedia ' + mediaType, fullScreen ? 'fullscreen': '']">
    <div class="c-mainmedia__inner" :style="{ height: totalHeight+'rem' }">
      <button class="c-mainmedia__resize" v-if="mediaType === 'video'" @click="resizeMedia"></button>
      <div class="c-mainmedia__media" :style="{ height: mediaHeight+'rem' }">
        <component
          v-bind:is="currentMediaView"
          :media="mainMedia.mediable"
          :key="mainMedia.id">
        </component>
      </div>
      <!--<div class="c-mainmedia__text">
        <h2 v-if="mainMedia.title">{{ mainMedia.title }}</h2>
        <p v-if="mainMedia.description">{{ mainMedia.description }}</p>
      </div>-->
    </div>
  </div>
</template>

<script>
import EmbedMedia from './EmbedMedia';
import FileMedia from './FileMedia';
import LinkMedia from './LinkMedia';

export default {
  name: 'c-mainmedia',
  components: {
    'c-embedmedia': EmbedMedia,
    'c-filemedia': FileMedia,
    'c-linkmedia': LinkMedia
  },
  props: ['setMediaHeight'],
  data () {
    return {
      mediaHeight: 0,
      totalHeight: 0,
      fullScreen: false
    }
  },
  mounted() {
    this.setHeight();
  },
  methods: {
    setHeight() {
      let h = 9;
      if(this.mediaType == 'audio') h = 2.5;

      this.mediaHeight = h;

      // if(this.mainMedia.title != '') h += 3;
      // if(this.mainMedia.description != '') h += 1;

      this.totalHeight = h;

      this.setMediaHeight(this.totalHeight);
    },
    resizeMedia() {
      if(this.fullScreen) {
        this.fullScreen = false;
        this.setHeight();
      }else{
        this.fullScreen = true;
        this.$nextTick(() => {
          let remPx = parseFloat(getComputedStyle(document.documentElement).fontSize);
          let h = (document.querySelector('.c-embed').getBoundingClientRect().height / remPx) + 3;
          this.totalHeight = h;
          this.setMediaHeight(h);
        });
      }
    }
  },
  computed: {
    mainMedia() {
      return this.$store.getters.mainMedia;
    },
    mediaType() {
      if(this.mainMedia.mediable_type == 'App\\Media\\EmbedItem') {
        return 'video';
      }else if(this.mainMedia.mediable_type == 'App\\Media\\FileItem') {
        let mime = this.mainMedia.mediable.mime;
        switch(mime) {
          case 'video/mp4':
            return 'video';
          case 'audio/mpeg':
          case 'application/octet-stream':
            return 'audio';
          case 'image/jpeg':
          case 'image/gif':
          case 'image/png':
            return 'image';
        }
      }else if(this.mainMedia.mediable_type == 'App\\Media\\LinkItem') {
        return 'link';
      }
      return null;
    },
    currentMediaView() {
      if(this.mainMedia.mediable_type == 'App\\Media\\EmbedItem') {
        return 'c-embedmedia';
      }else if(this.mainMedia.mediable_type == 'App\\Media\\FileItem') {
        return 'c-filemedia';
      }else if(this.mainMedia.mediable_type == 'App\\Media\\LinkItem') {
        return 'c-linkmedia';
      }
      return null;
    }
  }
}
</script>

<style lang="scss">

  @import '../styles/helpers';

  .c-mainmedia.fullscreen.video .c-mainmedia__media {
    width: 100%;
  }

  .c-mainmedia {
    position: fixed;
    top: 4.5rem;
    left: 0;
    width: 100%;
    height: 7rem;
    z-index: 2;

    .timed & {
      top: 6.5rem;
    }

    &.audio {
      height: 3rem;
    }
    &.video {

      .c-mainmedia__media {
        width: 14rem;
      }
    }

    &__inner {
      margin: 0 auto;
      max-width: 850px;
      height: 100%;
      padding: 5px 1rem;
      box-sizing: border-box;
      position: relative;
      overflow: hidden;
      background-color: #fafafa;
      border-bottom: 1px solid #eee;
    }
    &__media {
      margin: 0 auto;
      height: 100%;
      width: 100%;
    }

    &__text {
      padding: 1rem 0;

      h2, p {
        font-size: .8rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    &__resize {
      position: absolute;
      bottom: 10px;
      right: 10px;
      width: 20px;
      height: 20px;
      cursor: pointer;
      -webkit-appearance: none;
      padding: 0;
      border: none;
      background: none;
      font-size: 2rem;
      background-image: url('../assets/icon-fullscreen@2x.png');
      background-size: 100%;

      &:focus {
        outline: none;
      }
    }
  }
  .c-mainmedia.c-mainmedia.fullscreen.video .c-mainmedia__resize {
    background-image: url('../assets/icon-normalscreen@2x.png');
  }

</style>
