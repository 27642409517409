<template>
  <div class="c-mulchotaskscore">
    <span class="question">{{ taskid+1 }}. {{ task.taskable.question }}</span>
    <span v-if="isEmpty" class="answer answer--empty">Frage nicht beantwortet</span>
    <span
      v-bind:key="item.id"
      v-bind:class="{ answer: true, 'answer--correct': item.correct, 'answer--incorrect': (!item.correct && item.checked) }"
      v-for="item in shownUserAnswers"
    >
      <span v-if="item.correct && item.checked">✔</span>
      <span v-else>✖</span>
      {{ item.answer }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'c-mulchotaskscore',
  props: ['task', 'taskid'],
  data () {
    return {
    }
  },
  watch: {

  },
  mounted() {
  },
  methods: {
  },
  computed: {
    sheetId() {
      return this.$store.getters.sheetId;
    },
    sheetData() {
      return this.$store.getters.sheetData;
    },
    answers() {
      return this.$store.getters.answers;
    },
    correctAnswer() {
      let t = this.task.taskable.answers.filter((obj) => {
        return obj.correct == 1;
      });
      if(t.length == 0) return false;
      return t[0].answer;
    },
    userAnswers() {
      if(!this.answers[this.task.id]) return '';
      return this.answers[this.task.id].content;
    },
    shownUserAnswers() {
      if(this.isEmpty) return null;
      return this.userAnswers.filter(a => {
        return a.checked || a.correct;
      });
    },
    checkedAnswers() {
      return this.userAnswers.filter(a => {
        return a.checked;
      });
    },
    isEmpty() {
      if(this.checkedAnswers.length == 0) return true;
      if(!this.answers[this.task.id]) return true;
      if(this.answers[this.task.id] == '') return true;
      return false;
    },
    isCorrect() {
      if(!this.answers[this.task.id]) return false;
      return this.answers[this.task.id].correct;
    }
  }
}
</script>

<style lang="scss">

  @import '../../styles/helpers';

  .c-mulchotaskscore {
    margin-bottom: .5rem;
    border: 1px solid #eee;
    background-color: #fafafa;
    border-radius: 4px;
    padding: .5rem;

    .question {
      display: block;
      font-weight: 600;
      margin-bottom: .25rem;
    }

    .answer {
      display: block;
      padding: 5px;

      &--correct {
        background-color: #B3FDE2;
      }
      &--incorrect {
        background-color: #FFE4E4;
      }
      &--empty {
        background-color: #eee;
      }
    }

  }

</style>
