<template>
  <div class="c-listad">
    <a class="c-listad__link" :href="ad.link" target="_blank">
      <img class="thb" :src="ad.image" alt="ad.title">
      <h2>{{ ad.title }}</h2>
      <p>{{ ad.text }}</p>
    </a>
  </div>
</template>

<script>
export default {
  name: 'c-listad',
  props: ['ad'],
  data () {
    return {

    }
  },
  mounted() {
  },
  methods: {
  },
  computed: {
  }
}
</script>

<style lang="scss" scoped>

  @import '../styles/helpers';

  .c-listad {
    position: relative;

    &__link {
      display: block;
      text-decoration: none;
      border: 1px solid #ccc;
      overflow: hidden;
      background: #eee;
      border-radius: 4px;
      // box-shadow: 0 4px 20px -4px rgba(#000, .05);
      padding: 0;
      box-sizing: border-box;
      padding-bottom: 1rem;

      &:hover {
        box-shadow: 0 2px 10px -4px rgba(#000, .1);
        border-color: #ccc;
        
        h2 {
          text-decoration: underline;
        }
      }
    }

    img.thb {
      display: block;
      margin-bottom: .5rem;
      width: 100%;
      height: 8rem;
      object-fit: cover;
    }
    h2 {
      font-size: .8rem;
      font-weight: bold;
      margin-top: 1rem;
      margin-bottom: .25rem;
      padding: 0 1rem;
      color: #444;
    }
    p {
      color: #666;
      font-size: .8rem;
      padding: 0 1rem;
    }
  }


</style>
