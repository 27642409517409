<template>
  <div class="c-file">
    <div v-if="isImage" class="c-file__image">
      <img :src="media.url" />
    </div>
    <PdfEmbed v-if="isPdf" :media="media" />
    <div v-if="isAudio" class="c-file__audio">
      <audio :src="media.url" controls></audio>
    </div>
    <div v-if="isVideo" class="c-file__video">
      <video :src="media.url" controls></video>
    </div>
  </div>
</template>

<script>

import PdfEmbed from './PdfEmbed';

export default {
  name: "c-file",
  props: ["media"],
  data() {
    return {
      staticPdfUrl: "",
      directEmbed: true,
    };
  },
  components: { PdfEmbed },
  mounted() {
    window.setTimeout(() => {
      this.staticPdfUrl = this.pdfUrl;
    }, 1000);
    this.checkBrowser();
  },
  computed: {
    isImage() {
      return (
        this.media.mime == "image/jpeg" ||
        this.media.mime == "image/gif" ||
        this.media.mime == "image/png"
      );
    },
    isPdf() {
      return this.media.mime == "application/pdf";
    },
    isAudio() {
      return (
        this.media.mime == "audio/mpeg" ||
        this.media.mime == "application/octet-stream"
      );
    },
    isVideo() {
      return this.media.mime == "video/mp4";
    },
    pdfUrl() {
      return (
        "https://docs.google.com/viewer?url=" +
        this.media.url +
        "&embedded=true"
      );
    },
  },
  methods: {
    checkBrowser() {
      let isIE = /*@cc_on!@*/false || !!document.documentMode;
      let isIOS = /iPad|iPhone|iPod/.test(navigator.platform) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
      if(isIE || isIOS) {
        this.directEmbed = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import "../styles/helpers";

.c-file {
  background: #fafafa;
  padding: 1rem;
  padding: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;

  &__image {
    height: 100%;
    position: relative;
    z-index: 1;

    img {
      display: block;
      height: auto;
      width: auto;
      margin: 0 auto;
      max-height: 100%;
      max-width: 100%;
    }
  }
  &__iframe {
    width: 100%;
    height: calc(100vh - 14rem);
    min-height: 300px;
    position: relative;
    z-index: 1;

    iframe {
      width: 100%;
      height: calc(100% - 1.5rem);
    }

    @include desktop {
      height: calc(100vh - 16rem);
    }
  }
  &__embed {
    width: 100%;
    height: calc(100vh - 14rem);
    min-height: 300px;
    position: relative;
    z-index: 1;

    object {
      width: 100%;
      height: calc(100% - 1.5rem);
    }

    @include desktop {
      height: calc(100vh - 16rem);
    }
  }
  &__pdflink {
    display: inline-block;
    font-size: 0.8rem;
    margin-bottom: 0.5rem;
    color: #aaa;
  }
  &__audio {
    position: relative;
    z-index: 1;

    audio {
      width: 100%;
    }
  }
  &__video {
    position: relative;
    z-index: 1;

    video {
      width: 100%;
    }
  }
  &__loader {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 2rem;
    height: 2rem;
    margin-left: -1rem;
    margin-top: -1rem;
    border: 2px solid #ddd;
    border-bottom-color: transparent;
    border-radius: 100%;
    z-index: 0;
    animation: loadingspin linear 1s infinite;
  }
}
</style>
