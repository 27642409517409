<template>
  <div class="c-media">
    <div class="c-media__content" v-if="currentMedia">
      <h1 v-if="currentMedia.title">{{ currentMedia.title }}</h1>
      <p class="c-media__description" v-if="currentMedia.description">{{ currentMedia.description }}</p>
      <component
        v-bind:is="currentMediaView"
        :media="currentMedia.mediable"
        :key="currentMedia.id">
      </component>
      <p class="c-media__reference" v-if="currentMedia.reference">{{ currentMedia.reference }}</p>
    </div>
    <c-footer :prevRoute="prevRoute" :nextRoute="nextRoute" :activeTaskRoute="activeTaskRoute"></c-footer>
  </div>
</template>

<script>
import Footer from './Footer';
import EmbedMedia from './EmbedMedia';
import FileMedia from './FileMedia';
import LinkMedia from './LinkMedia';

export default {
  name: 'media',
  components: {
    'c-footer': Footer,
    'c-embedmedia': EmbedMedia,
    'c-filemedia': FileMedia,
    'c-linkmedia': LinkMedia
  },
  data () {
    return {

    }
  },
  mounted() {
    this.$store.commit('setSheetProgress', 0);
    this.updateHeader();
  },
  watch: {
    sheetData: function() {
      this.$store.commit('setSheetProgress', 0);
      this.updateHeader();
    },
    mediaId: function(val) {
      if(!val) return;
      this.$store.commit('setSheetProgress', 0);
      this.updateHeader();
    }
  },
  methods: {
    updateHeader() {
      if(!this.sheetData) return;

      let subtitle = 'Media ' + (this.mediaId + 1) + ' / ' + this.sheetData.media.length;

      this.$store.commit('setHeader', {
        title: this.sheetData.name,
        subtitle: subtitle
      });
    }
  },
  computed: {
    currentMediaView() {
      if(this.currentMedia.mediable_type == 'App\\Media\\EmbedItem') {
        return 'c-embedmedia';
      }else if(this.currentMedia.mediable_type == 'App\\Media\\FileItem') {
        return 'c-filemedia';
      }else if(this.currentMedia.mediable_type == 'App\\Media\\LinkItem') {
        return 'c-linkmedia';
      }
      return null;
    },
    sheetData() {
      return this.$store.getters.sheetData;
    },
    sheetId() {
      return this.$route.params.sheetId;
    },
    mediaId() {
      return parseInt(this.$route.params.mediaId);
    },
    currentMedia() {
      if(!this.$store.getters.sheetData) return;
      return this.$store.getters.sheetData.media[this.mediaId];
    },
    prevRoute() {
      if(!this.$store.getters.sheetData) return '';

      if(this.mediaId == 0) {
        return '/sheets/' + this.sheetId;
      }else{
        return '/sheets/' + this.sheetId + '/media/' + (this.mediaId - 1);
      }
    },
    nextRoute() {
      if(!this.$store.getters.sheetData) return '';

      if(this.$store.getters.sheetData.media[this.mediaId + 1]) {
        return '/sheets/' + this.sheetId + '/media/' + (this.mediaId + 1);
      }else{
        return '/sheets/' + this.sheetId + '/tasks/0';
      }
    },
    activeTaskRoute() {
      if(!this.$store.getters.activeTask) return null;
      return '/sheets/' + this.sheetId + '/tasks/' + this.$store.getters.activeTask;
    }
  }
}
</script>

<style lang="scss">

  @import '../styles/helpers';

  .c-media {

    &__description {
      margin-bottom: 1rem;
    }
    &__reference {
      margin-top: .5rem;
      font-size: .8rem;
      color: #888;
    }
  }

  .c-media__content {
    @include routerTransition;
  }

</style>
