<template>
  <div class="c-embed">
    <iframe :src="mediaUrl" frameborder="0" allowfullscreen></iframe>
  </div>
</template>

<script>
export default {
  name: 'c-embed',
  props: ['media'],
  data () {
    return {

    }
  },
  mounted() {
  },
  computed: {
    mediaUrl() {
      let url = new URL(this.media.url);
      url.searchParams.set('playsinline', 1);
      return url.href;
    }
  },
  methods: {

  }
}
</script>

<style lang="scss">

  .c-embed {
    height: 0;
    width: 100%;
    margin-left: 0;
    padding-bottom: 56.25%;
    position: relative;
    background-color: #eee;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

</style>
