<template>
  <div class="c-score">
    <c-scoredetail v-if="showDetailModal" @close="showDetailModal = false"></c-scoredetail>
    <c-scoreshare v-if="showShareModal" @close="showShareModal = false" :scoreData="scoreData"></c-scoreshare>
    <div class="c-score__content" v-if="sheetData && answerCount">
      <h1>Auswertung</h1>
      <div class="c-score__bar-container">
        <div class="c-score__bar c-score__bar--correct" v-bind:style="{ width: percentageCorrect + '%' }"></div>
        <div class="c-score__bar c-score__bar--incorrect" v-bind:style="{ width: percentageIncorrect + '%' }"></div>
        <div class="c-score__bar c-score__bar--empty" v-bind:style="{ width: percentageEmpty + '%' }"></div>
      </div>
      <div class="c-score__count-container">
        <span class="c-score__count c-score__count--correct"><strong>{{ countCorrect }}</strong> Richtig</span>
        <span class="c-score__count c-score__count--incorrect"><strong>{{ countIncorrect }}</strong> Falsch</span>
        <!--<span class="c-score__count c-score__count--empty"><strong>{{ countEmpty }}</strong> Unbeantwortet</span>-->
        <span class="c-score__count c-score__count--selfcheck"><strong>{{ countSelfcheck }}</strong> Selbstkontrolle</span>
      </div>
      <div class="c-score__details" v-if="false">
        <ul>
          <li v-bind:key="task.id" v-for="task in sheetData.tasks"><h2>{{ task.name }}</h2></li>
        </ul>
      </div>
      <a href="#" class="c-score__showdetail" v-on:click="showDetailModal = true">Detailauswertung anzeigen</a>
      <a href="#" class="c-score__showdetail" v-on:click="showShareModal = true">Auswertung als E-Mail senden</a>
    </div>
    <div class="c-score__content" v-else>
      <h1>Auswertung</h1>
      <p>Es wurden keine Fragen beantwortet.</p>
    </div>
    <router-link :to="'/sheets/' + this.sheetId" class="c-score__button">Wiederholen</router-link>
  </div>
</template>

<script>
import ScoreDetail from './ScoreDetail';
import ScoreShare from './ScoreShare';

export default {
  name: 'score',
  data () {
    return {
      showDetailModal: false,
      showShareModal: false
    }
  },
  components: {
    'c-scoredetail': ScoreDetail,
    'c-scoreshare': ScoreShare
  },
  watch: {
    sheetData: function() {
      this.updateHeader();
    }
  },
  mounted() {
    this.$store.dispatch('stopTimer');
    if(this.answerCount < 1) {
      // console.log('no answers');
    }

    this.updateHeader();
  },
  methods: {
    updateHeader() {
      if(!this.sheetData) return;

      this.$store.commit('setHeader', {
        title: this.sheetData.name,
        subtitle: 'Auswertung'
      });

      this.$store.commit('setSheetProgress', 100);
    }
  },
  computed: {
    sheetId() {
      return this.$store.getters.sheetId;
    },
    sheetData() {
      return this.$store.getters.sheetData;
    },
    answers() {
      return this.$store.getters.answers;
    },
    answerCount() {
      return Object.keys(this.answers).length;
    },
    taskCount() {
      return this.sheetData.tasks.length;
    },
    nextRoute() {
      if(this.sheetData.media.length > 0) {
        return '/sheets/' + this.sheetId + '/media/1';
      }else if(this.sheetData.tasks.length > 0) {
        return '/sheets/' + this.sheetId + '/tasks/1';
      }else {
        return '/';
      }
    },
    percentageCorrect() {
      return Math.round((100/this.taskCount) * this.countCorrect);
    },
    percentageIncorrect() {
      return Math.round((100/this.taskCount) * this.countIncorrect);
    },
    percentageSelfcheck() {
      return Math.round((100/this.taskCount) * this.countSelfcheck);
    },
    percentageEmpty() {
      return Math.round((100/this.taskCount) * this.countEmpty);
    },
    countCorrect() {
      let correctCount = 0;
      Object.keys(this.answers).forEach(a => {
        if(this.answers[a].correct == 1) correctCount++;
      });
      return correctCount;
    },
    countIncorrect() {
      let incorrectCount = 0;
      Object.keys(this.answers).forEach(a => {
        if(this.answers[a].correct == 0) incorrectCount++;
      });
      return incorrectCount;
    },
    countSelfcheck() {
      let selfcheckCount = 0;
      Object.keys(this.answers).forEach(a => {
        if(this.answers[a].correct == 2) selfcheckCount++;
      });
      return selfcheckCount;
    },
    countEmpty() {
      let c = this.sheetData.subtask_count - this.answerCount;
      return c;
    },
    scoreData() {
      return {
        taskCount: this.taskCount,
        countCorrect: this.countCorrect,
        countIncorrect: this.countIncorrect,
        countEmpty: this.countEmpty
      }
    }
  }
}
</script>

<style lang="scss">

  @import '../styles/helpers';

  .c-score {

    h1 {
      font-size: 2rem;
    }

    &__bar-container {
      width: 100%;
      height: 2rem;
      background-color: #f0f0f0;
      display: flex;
      margin-top: 1.5rem;
      margin-bottom: .5rem;
      border-radius: 2rem;
      overflow: hidden;
      position: relative;
      // border: 1px solid #aaa;

      &::after {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        box-shadow: inset 0 -3px 0 0 rgba(#fff, .5);
        border-radius: 2rem;
      }
    }
    &__bar {
      height: 2rem;

      &--correct {
        background-color: #00B475;
      }
      &--incorrect {
        background-color: $c-red;
      }
      &--empty {
        background-color: #ccc;
      }
    }
    &__count-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    &__count {
      display: block;
      width: 100%;
      text-align: center;
      margin: .25rem 0;
      background-color: #fafafa;
      border: 1px solid #eee;
      border-radius: 1rem;
      height: 2rem;
      line-height: 2rem;

      @include desktop {
        width: calc(50% - .25rem);
      }
      &--correct {
        background-color: $c-green-bg;
        border-color: darken($c-green-bg, 10%);
      }
      &--incorrect {
        background-color: $c-red-bg;
        border-color: darken($c-red-bg, 5%);
      }
      &--selfcheck {
        background-color: rgb(253, 253, 198);
        border-color: rgb(230, 230, 180);
      }
    }

    &__button {
      position: fixed;
      font-family: $f-body;
      bottom: 1.5rem;
      left: 50%;
      display: block;
      background: $c-red;
      border: 2px solid $c-red-dark;
      text-shadow: 0 2px 0 $c-red-dark;
      box-shadow: 0 5px 10px 0 rgba($c-red-dark, .15);
      font-size: 1rem;
      font-weight: 700;
      color: #fff;
      padding: 0;
      height: 2.5rem;
      line-height: 2.5rem;
      border-radius: 2.5rem;
      text-decoration: none;
      text-align: center;
      width: calc(100% - 3rem);
      max-width: calc(850px - 3rem);
      transform: translateX(-50%);
      box-sizing: content-box;

      &:hover {
        background-color: $c-red-light;
      }
    }

    &__showdetail {
      display: block;
      margin-top: 1rem;
      text-align: center;
    }
  }

  .c-score__content {
    @include routerTransition;
  }

</style>
