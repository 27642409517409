<template>
  <div class="c-task">
    <transition name="mediamodal">
      <div
        class="c-task__media"
        v-if="mediaVisible"
        @click="mediaVisible = false"
      >
        <div class="c-task__media__inner" @click.stop>
          <button @click="mediaVisible = false" class="c-task__media__close">
            ✕
          </button>
          <div class="c-task__media__mediable">
            <component
              v-bind:is="currentMediaView"
              :media="currentMedia.mediable"
              :key="currentMedia.id"
            >
            </component>
          </div>
          <div class="c-task__media__content">
            <h3>{{ currentMedia.title }}</h3>
            <p>{{ currentMedia.description }}</p>
          </div>
        </div>
      </div>
    </transition>
    <div class="c-task__content" v-if="currentTask">
      <div class="c-task__mediabuttons" v-if="currentTask.media.length >= 1">
        <template v-for="media in currentTask.media">
          <button
            :class="getMediaButtonClass(media)"
            @click="showMedia(media)"
            v-bind:key="media.id"
            v-if="!isImage(media)"
          >
            {{ media.preview }}
          </button>
          <a href="#" @click.prevent="showMedia(media)"
            class="c-task__mediabuttons__image"
            :key="media.id"
            v-if="isImage(media)"
          >
            <img :src="media.mediable.resized">
          </a>
        </template>
      </div>
      <!--<div v-if="currentTask.description" v-html="compiledDescription" class="c-task__description"></div>-->
      <component
        v-bind:is="currentView"
        :task="currentTask.taskable"
        :parent="currentTask"
        :description="compiledDescription"
        :key="currentTask.id"
        :taskEntering="taskEntering"
        @proceed="proceed"
      >
      </component>
    </div>
    <c-footer
      :prevRoute="prevRoute"
      :nextRoute="nextRoute"
      :mediaRoute="mediaRoute"
    ></c-footer>
  </div>
</template>

<script>
import Footer from "../Footer";
import MulchoTask from "./MulchoTask";
import InputTask from "./InputTask";
import GapsTask from "./GapsTask";
import WordsTask from "./WordsTask";
import EmbedMedia from "../EmbedMedia";
import FileMedia from "../FileMedia";
import LinkMedia from "../LinkMedia";

import marked from "marked";

export default {
  name: "task",
  props: ["taskEntering"],
  components: {
    "c-footer": Footer,
    "c-mulchotask": MulchoTask,
    "c-inputtask": InputTask,
    "c-gapstask": GapsTask,
    "c-wordstask": WordsTask,
    "c-embedmedia": EmbedMedia,
    "c-filemedia": FileMedia,
    "c-linkmedia": LinkMedia,
  },
  data() {
    return {
      mediaVisible: false,
      currentMedia: null,
    };
  },
  mounted() {
    this.updateHeader();
    this.updateSheetProgress();
  },
  watch: {
    sheetData: function () {
      this.updateHeader();
      this.updateSheetProgress();
    },
    taskId: function (val) {
      this.updateHeader();
      this.updateSheetProgress();
    },
  },
  methods: {
    updateHeader() {
      if (!this.sheetData) return;

      let subtitle =
        "Aufgabe " + (this.taskId + 1) + " / " + this.sheetData.tasks.length;

      this.$store.commit("setHeader", {
        title: this.sheetData.name,
        subtitle: subtitle,
      });
    },
    updateSheetProgress() {
      if (!this.sheetData) return;

      let prog = (100 / this.sheetData.tasks.length) * (this.taskId + 1);
      this.$store.commit("setSheetProgress", prog);
      this.$store.commit("setActiveTask", this.taskId);
    },
    showMedia(media) {
      this.mediaVisible = true;
      this.currentMedia = media;
    },
    getMediaButtonClass(media) {
      let cl = ["c-task__mediabutton"];
      let type = media.mediable_type;

      if (type == "App\\Media\\EmbedItem") {
        cl.push("c-task__mediabutton--embed");
      } else if (type == "App\\Media\\FileItem") {
        if (media.mediable.mime.substr(0, 5) == "image") {
          cl.push("c-task__mediabutton--image");
        } else {
          cl.push("c-task__mediabutton--file");
        }
      } else if (type == "App\\Media\\LinkItem") {
        cl.push("c-task__mediabutton--link");
      }

      return cl;
    },
    proceed() {
      this.$store.commit("setRouterTransition", "slide-left");
      this.$router.push({ path: this.nextRoute });
    },
    isImage(media) {
      if (media.mediable_type != "App\\Media\\FileItem") return;
      return media.mediable.mime.substr(0, 5) == "image";
    },
  },
  computed: {
    currentView() {
      if (this.currentTask.taskable_type == "App\\Tasks\\MulchoTask") {
        return "c-mulchotask";
      } else if (this.currentTask.taskable_type == "App\\Tasks\\InputTask") {
        return "c-inputtask";
      } else if (this.currentTask.taskable_type == "App\\Tasks\\GapsTask") {
        return "c-gapstask";
      } else if (this.currentTask.taskable_type == "App\\Tasks\\WordsTask") {
        return "c-wordstask";
      }
      return null;
    },
    currentMediaView() {
      if (this.currentMedia.mediable_type == "App\\Media\\EmbedItem") {
        return "c-embedmedia";
      } else if (this.currentMedia.mediable_type == "App\\Media\\FileItem") {
        return "c-filemedia";
      } else if (this.currentMedia.mediable_type == "App\\Media\\LinkItem") {
        return "c-linkmedia";
      }
      return null;
    },
    sheetData() {
      return this.$store.getters.sheetData;
    },
    sheetId() {
      return this.$route.params.sheetId;
    },
    taskId() {
      return parseInt(this.$route.params.taskId);
    },
    currentTask() {
      if (!this.sheetData) return;
      return this.sheetData.tasks[this.taskId];
    },
    prevRoute() {
      if (!this.sheetData) return "";

      if (this.taskId == 0) {
        if (this.sheetData.media.length > 0) {
          return (
            "/sheets/" +
            this.sheetId +
            "/media/" +
            (this.sheetData.media.length - 1)
          );
        } else {
          return "/sheets/" + this.sheetId;
        }
      } else {
        return "/sheets/" + this.sheetId + "/tasks/" + (this.taskId - 1);
      }
    },
    nextRoute() {
      if (!this.sheetData) return "";

      if (this.sheetData.tasks[this.taskId + 1]) {
        return "/sheets/" + this.sheetId + "/tasks/" + (this.taskId + 1);
      } else {
        return "/sheets/" + this.sheetId + "/score";
      }
    },
    mediaRoute() {
      if (!this.sheetData) return "";

      if (this.sheetData.media.length > 0) {
        return "/sheets/" + this.sheetId + "/media/0";
      }
    },
    compiledDescription() {
      return marked(this.currentTask.description);
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/helpers";

.c-task {
  &__description {
    background-color: #fafafa;
    max-width: none;
    margin-bottom: 1rem;
    padding: 0.5rem 0.8rem;
    border: 1px solid #eee;
    color: #666;
    font-size: 0.8rem;
    border-radius: 4px;

    h1 {
      font-size: 1rem;
    }
    h2 {
      font-size: 0.8rem;
      font-weight: 700;
      margin: 0.5rem 0;
    }
    ul {
      margin: 0.5rem 0;

      li {
        &::before {
          content: "— ";
        }
      }
    }
  }

  // h1 {
  //   font-size: 2rem;
  // }

  &__mediabuttons {
    margin-bottom: 1rem;

    &__image {
      display: block;
      background-color: #f6f6f6;
      width: 100%;
      padding: .5rem;
      box-sizing: border-box;
      border-radius: 4px;

      &:hover {
        box-shadow: 0 0 0 1px #ddd;

        img {
          opacity: .9;
        }
      }

      img {
        display: block;
        margin: 0 auto;
        max-height: 10rem;
        width: auto;
      }
    }
  }
  &__mediabutton {
    font-family: $f-body;
    font-size: 1rem;
    padding: 0;
    background-color: #f6f6f6;
    border: none;
    color: #000;
    width: 100%;
    padding: 0 0.5rem;
    line-height: 2.5rem;
    margin-bottom: 0.5rem;
    border-radius: 2rem;
    height: 2.5rem;
    text-align: left;
    padding-left: 60px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    background-position: 15px center;
    background-size: 25px 25px;
    background-repeat: no-repeat;
    text-decoration: underline;
    cursor: pointer;

    &:hover,
    &:focus,
    &:active {
      box-shadow: 0 0 0 1px #ddd;
      outline: none;
    }

    &--file {
      background-image: url("../../assets/icon-media_file.png");
    }
    &--image {
      background-image: url("../../assets/icon-media_image.png");
    }
    &--embed {
      background-image: url("../../assets/icon-media_embed.png");
    }
    &--link {
      background-image: url("../../assets/icon-media_link.png");
    }
  }

  &__media {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(#000, 0.8);
    z-index: 99;
    // display: flex;
    // justify-content: center;
    overflow-y: scroll;
    padding: 2rem 0;
    box-sizing: border-box;

    &__content {
      margin-top: 1rem;

      h3 {
        font-weight: bold;
      }
    }
    &__mediable {
      height: auto;
    }
    &__inner {
      width: calc(100% - 4rem);
      margin-left: 2rem;
      height: auto;
      // min-height: 90%;
      position: relative;
      background-color: #fff;
      padding: 1rem;
      box-sizing: border-box;

      .c-file {
        width: 100%;
        height: 100%;
      }
    }

    &__close {
      position: absolute;
      top: -1rem;
      right: -1rem;
      background: none;
      border: none;
      font-family: $f-body;
      font-size: 1rem;
      background-color: #fff;
      border: 2px solid #000;
      width: 2rem;
      height: 2rem;
      line-height: 2rem;
      border-radius: 99%;
      padding: 0;
      box-sizing: content-box;
      z-index: 2;
      text-align: center;
      cursor: pointer;
    }
  }
}

.mediamodal-enter-active,
.mediamodal-leave-active {
  transition: opacity 0.3s;
  opacity: 1;

  .c-task__media__inner {
    transition: transform 0.3s ease-out;
    transform: translateY(0);
  }
}
.mediamodal-enter, .mediamodal-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;

  .c-task__media__inner {
    transform: translateY(40px);
  }
}

.c-task__content {
  @include routerTransition;
}
</style>
