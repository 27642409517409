<template>
  <div class="c-gapstask">
    <div class="c-gapstask__title" v-html="compiledTitle"></div>
    <div v-if="description" class="c-task__description" v-html="description"></div>
    <div class="c-gapstask__text" ref="text">
      {{ task.text }}
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import GapsTaskGap from './GapsTaskGap';
const GapsTaskGapCstr = Vue.extend(GapsTaskGap);

import marked from 'marked';

export default {
  name: 'gapstask',
  props: ['task', 'description'],
  components: {
    'c-gapstaskgap': GapsTaskGap
  },
  data () {
    return {
      input: null,
      correct: false,
      formattedText: '',
      focusedGap: 0,
      gapsCount: 0
    }
  },
  mounted() {
    this.initGaps();
  },
  watch: {
    input: function(val) {
      this.checkInput(val);
    }
  },
  methods: {
    initGaps() {
      this.bus = new Vue();
      this.bus.$on('enter', (id) => {
        this.focusNextGap(id);
      });

      this.formattedText = this.decodeText(this.task.textHtml);
      this.$refs.text.innerHTML = this.formattedText;

      let gaps = document.querySelectorAll('.newgap');
      this.gapsCount = gaps.length;
      for (let i = 0; i < gaps.length; ++i) {
        let answers = gaps[i].innerText.split(';');

        new GapsTaskGapCstr({
          el: gaps[i],
          propsData: {
            'id': i,
            'gapId': this.task.id + 'g' + i,
            'answers': answers,
            'store': this.$store,
            'checkCap': this.task.check_capitalization,
            'bus': this.bus
          }
        });
      }
    },
    decodeText(text) {
      let t = marked(text);
      t = t.replace(/(\[)([^}]*?)(\])/g, '<span class="newgap">$2</span>');
      return t;
    },
    focusNextGap(currentId) {
      if(currentId + 1 >= this.gapsCount) return;
      this.focusedGap = currentId + 1;
      this.bus.$emit('focusChange', this.focusedGap);
    }
  },
  computed: {
    sheetData() {
      return this.$store.getters.sheetData;
    },
    sheetId() {
      return this.$route.params.sheetId;
    },
    taskId() {
      return this.$route.params.taskId;
    },
    currentTask() {
      if(!this.sheetData) return;
      return this.sheetData.tasks[(this.taskId)];
    },
    compiledTitle() {
      return marked(this.task.title);
    }
  }
}
</script>

<style lang="scss">

  @import '../../styles/helpers';

  .newgap {
    background: #aff;
  }

  .c-gapstask {

    &__title {
      font-weight: bold;
      margin-bottom: 1rem;
      font-size: 1.25rem;
      width: 100%;

      @include desktop() {
        font-size: 1.5rem;
      }
    }

    &__text {
      margin-top: 2rem;
      line-height: 1.6;
    }

  }

</style>
