<template>
  <div class="c-categories" v-if="categories">
    <ul class="c-categories__list">
      <li v-for="(cat, index) in categories" :key="cat.id" :class="{ 'active': cat.active }">
        <a href="#" @click.prevent="$store.commit('toggleCategory', index)">{{ cat.title }}</a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'c-categories',
  props: [],
  data () {
    return {
      selfCats: []
    }
  },
  watch: {
    query() {
    }
  },
  mounted() {
  },
  methods: {
  },
  computed: {
    categories() {
      return this.$store.state.categories;
    },
    activeCategoriesString() {
      return this.$store.getters.activeCategoriesString;
    }
  }
}
</script>

<style lang="scss" scoped>

  @import '../styles/helpers';

  .c-categories {
    // margin-top: 1rem;
    margin: 0 0 2rem 0;

    &__list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin: 0 auto;
      max-width: 800px;

      li {
        display: inline-block;
        font-size: .8rem;
        margin: .25rem .25rem 0rem 0;

        a {
          display: block;
          padding: 0 1rem;
          line-height: 1.75rem;
          text-decoration: none;
          background-color: #fafafa;
          border: 1px solid #eee;
          border-radius: 4px;
          color: #777;
          // box-shadow: 0 1px 8px -2px rgba(#000, .3);
        }

        &.active {

          a {
            border-color: #333;;
            box-shadow: 0 2px 10px -2px rgba(#000, .2);
            background-color: #666;
            color: #fff;
          }
        }
      }
    }
  }

</style>
