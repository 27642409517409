<template>
  <input
    ref="el"
    @keyup.enter="onEnter"
    v-model="input"
    v-bind:class="{ correct: correct }"
    :size="inputSize"
    class="c-gapstask__gap"
    type="text"
  />
</template>

<script>
export default {
  name: "gapstaskgap",
  props: ["id", "gapId", "answers", "store", "checkCap", "bus"],
  data() {
    return {
      input: null,
      correct: false,
    };
  },
  mounted() {
    if (this.store.getters.answers[this.gapId]) {
      this.input = this.store.getters.answers[this.gapId].content;
    }

    this.bus.$on("focusChange", (id) => {
      if (id == this.id) {
        this.$refs.el.focus();
      }
    });
  },
  watch: {
    input: function (val) {
      this.checkInput(val);
    },
  },
  methods: {
    checkInput(val) {
      let isCorrect = false;
      this.answers.forEach((el) => {
        let a = el.trim();
        let b = val.trim();
        if (!this.checkCap) {
          a = el.toLowerCase();
          b = val.toLowerCase();
        }
        a = a.replace(/[`’‘‛]/g, "'");
        b = b.replace(/[`’‘‛]/g, "'");
        if (a == b) isCorrect = true;
      });
      if (isCorrect) {
        this.correct = 1;
      } else {
        this.correct = 0;
      }

      this.store.commit("addAnswer", {
        id: this.gapId,
        content: this.input,
        correct: this.correct,
      });
    },
    onEnter(e) {
      e.preventDefault();
      this.bus.$emit("enter", this.id);
    },
  },
  computed: {
    inputSize() {
      if (!this.input) return 5;
      return this.input.length + 1;
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/helpers";

input.c-gapstask__gap {
  // width: 6rem;
  font-family: $f-body;
  // font-family: monospace;
  font-size: 1rem;
  padding: 0.25rem;
  margin: 0.25rem 0.25rem;
  box-sizing: border-box;
  background: #f6f6f6;
  border: 1px solid #e0e0e0;
  max-width: 100%;

  &:focus {
    outline: none;
    border-color: #333;
  }
  &.correct {
    // background-color: #efe;
  }

  @include desktop() {
    // width: 10rem;
  }
}
</style>
