<template>
  <div class="c-footer">
    <button @click="pushTaskRoute" class="c-footer__link c-footer__link--resume" v-if="activeTaskRoute" title="Zurück zum Task"><span>Zurück zum Task</span></button>
    <button @click="pushMediaRoute" class="c-footer__link c-footer__link--media" v-if="mediaRoute" title="Zurück zu den Medien"><span>Zurück zu den Medien</span></button>
    <button @click="pushPrevRoute" class="c-footer__link c-footer__link--back" v-if="prevRoute" title="Zurück"><span>Zurück</span></button>
    <button @click="pushNextRoute" class="c-footer__link c-footer__link--forward" v-if="nextRoute" :title="nextText"><span>{{ nextText }}</span></button>
  </div>
</template>

<script>
export default {
  name: 'c-footer',
  props: ['prevRoute', 'nextRoute', 'mediaRoute', 'activeTaskRoute'],
  data () {
    return {

    }
  },
  mounted() {
  },
  methods: {
    pushMediaRoute() {
      this.$store.commit('setRouterTransition', 'slide-right');
      this.$router.push({ path: this.mediaRoute });
    },
    pushNextRoute() {
      this.$store.commit('setRouterTransition', 'slide-left');
      this.$router.push({ path: this.nextRoute });
    },
    pushPrevRoute() {
      this.$store.commit('setRouterTransition', 'slide-right');
      this.$router.push({ path: this.prevRoute });
    },
    pushTaskRoute() {
      this.$store.commit('setRouterTransition', 'slide-left');
      this.$router.push({ path: this.activeTaskRoute });
    }
  },
  computed: {
    nextText() {
      if(this.nextRoute.substring(this.nextRoute.length - 5) == 'score') return 'Zur Auswertung';
      return 'Weiter';
    }
  }
}
</script>

<style lang="scss">

  @import '../styles/helpers';

  .c-footer {
    position: fixed;
    bottom: 1.5rem;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 850px;
    display: flex;
    justify-content: space-between;
    padding: 0 1.5rem;
    box-sizing: border-box;

    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 6rem;
      pointer-events: none;
      position: absolute;
      bottom: -1.5rem;
      left: 0;
      background-image: linear-gradient(-180deg, rgba(#fff,0) 0%, rgba(#fff,.8) 30%, #fff 100%);
    }

    &__link {
      display: block;
      cursor: pointer;
      padding: 0;
      background: $c-red;
      border: 2px solid $c-red-dark;
      box-sizing: content-box;
      text-shadow: 0 1px 0 $c-red-dark;
      // box-shadow: 0 5px 10px 0 rgba($c-red-dark, .15);
      font-family: $f-body;
      font-weight: 700;
      font-size: 1rem;
      color: #fff;
      line-height: 2.5rem;
      height: 2.5rem;
      border-radius: 2.5rem;
      text-decoration: none;
      text-align: center;
      width: calc(50% - .75rem);
      position: relative;
      z-index: 1;

      &--back {
        width: 2.5rem;
        background-image: url('../assets/icon-back.png');
        background-size: 48px 48px;
        background-position: center;

        @include retina {
          background-image: url('../assets/icon-back@2x.png');
        }

        span {
          display: none;
        }
      }
      &--media {
        width: 2.5rem;
        background-image: url('../assets/icon-mediaback.png');
        background-size: 48px 48px;
        background-position: center;
        margin-right: .5rem;

        @include retina {
          background-image: url('../assets/icon-mediaback@2x.png');
        }

        span {
          display: none;
        }
      }
      &--resume {
        width: 2.5rem;
        background-image: url('../assets/icon-resume.png');
        background-size: 48px 48px;
        background-position: center;
        margin-right: .5rem;

        @include retina {
          background-image: url('../assets/icon-resume@2x.png');
        }

        span {
          display: none;
        }
      }
      &--forward {
        width: calc(100% - 3.75rem);
        width: auto;
        flex-grow: 1;
        margin-left: .5rem;
      }

      &:focus {
        outline: none;
        border-color: darken($c-red, 30%);
        background-color: $c-red-light;
      }
      &:hover {
        background-color: $c-red-light;
      }
    }
  }

</style>
